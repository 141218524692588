export const variants: any = {
  success: "success",
  danger: "danger",
};

export const placements: any = {
  top: "top",
  bottom: "bottom",
};

export const durations: any = {
  shortest: 500,
  short: 1500,
  default: 3000,
  long: 6000,
};
