import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const styleProps = {
  size: "text-3xl",
  leading: "leading-9",
  weight: "font-bold",
};

export interface TitleLgProps extends TextInternalProps {
  "data-testid"?: string;
}

export const TitleLg = ({
  color,
  truncate,
  [DATA_TESTID]: dataTestId,
  children,
  ...otherProps
}: TitleLgProps) => {
  const props = {
    ...styleProps,
    color,
    truncate,
    dataTestId,
    ...otherProps,
  };

  return (
    <Text_INTERNAL as="h3" {...props}>
      {children}
    </Text_INTERNAL>
  );
};
