import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const styleProps = {
  size: "text-xxs",
  leading: "leading-4",
  weight: "font-semibold",
  tracking: "tracking-wide",
  textTransform: "uppercase",
};

export interface LabelXxsProps extends TextInternalProps {
  "data-testid"?: string;
}

export const LabelXxs = ({
  color,
  leading,
  truncate,
  [DATA_TESTID]: dataTestId,
  children,
}: LabelXxsProps) => {
  const props = {
    ...styleProps,
    color,
    leading,
    truncate,
    dataTestId,
  };

  return (
    <Text_INTERNAL as="span" {...props}>
      {children}
    </Text_INTERNAL>
  );
};
