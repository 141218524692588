import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const styleProps = {
  size: "text-5xl",
  leading: "leading-11",
  weight: "font-bold",
};

export interface TitleXxlProps extends TextInternalProps {
  "data-testid"?: string;
}

export const TitleXxl = ({
  color,
  truncate,
  [DATA_TESTID]: dataTestId,
  children,
  ...otherProps
}: TitleXxlProps) => {
  const props = {
    ...styleProps,
    color,
    truncate,
    dataTestId,
    ...otherProps,
  };

  return (
    <Text_INTERNAL as="h1" {...props}>
      {children}
    </Text_INTERNAL>
  );
};
