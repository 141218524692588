import { DATA_TESTID, Text_INTERNAL } from "../Text_INTERNAL";
import type { TextInternalProps } from "../Text_INTERNAL";

export const FONT_WEIGHTS = ["bold", "semibold"];

export const styleProps = {
  size: "text-xl",
  leading: "leading-7",
};

export interface TitleSmProps extends TextInternalProps {
  "data-testid"?: string;
}

export const TitleSm = ({
  weight = "normal",
  color,
  truncate,
  [DATA_TESTID]: dataTestId,
  children,
  ...otherProps
}: TitleSmProps) => {
  const props = {
    ...styleProps,
    dataTestId,
    weight: `font-${weight}`,
    color,
    truncate,
    ...otherProps,
  };

  return (
    <Text_INTERNAL as="h5" {...props}>
      {children}
    </Text_INTERNAL>
  );
};
