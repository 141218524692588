import { useCallback, useEffect, useState } from "react";
import isFunction from "lodash/isFunction";
import { durations } from "../constants";

export const useToastMessage = ({
  shouldAutohide = true,
  shouldUseProgress,
  duration = durations.default,
  checkmarkDuration = durations.shortest,
  fadeOutDuration = durations.shortest,
  onRemove,
  onComplete,
  onProgress,
}) => {
  const [isShowing, setIsShowing] = useState(true);
  const [progress, setProgress] = useState(0);
  const speed = duration / 180;

  const hasOnComplete = isFunction(onComplete);
  const hasOnProgress = isFunction(onProgress);

  const handleComplete = useCallback(() => {
    hasOnComplete && onComplete();
  }, [hasOnComplete, onComplete]);

  const handleClose = useCallback(() => {
    setIsShowing(false);
    setTimeout(() => {
      onRemove();
    }, fadeOutDuration);
  }, [fadeOutDuration, onRemove]);

  useEffect(() => {
    if (!shouldAutohide || hasOnProgress) {
      return;
    }

    const fadeOutTimer = setTimeout(() => {
      handleClose();
    }, duration + checkmarkDuration);

    return () => {
      clearTimeout(fadeOutTimer);
    };
  }, [shouldAutohide, hasOnProgress, duration, checkmarkDuration, handleClose]);

  useEffect(() => {
    if (!shouldUseProgress) {
      return;
    }

    let fadeOutTimer;

    if (progress === 100) {
      handleComplete();

      if (hasOnProgress) {
        fadeOutTimer = setTimeout(() => {
          handleClose();
        }, checkmarkDuration);
      }
      return;
    }

    const progressTimer = setTimeout(() => {
      setProgress(prev => {
        if (hasOnProgress) {
          return onProgress(prev);
        }
        return prev + 1;
      });
    }, speed);

    return () => {
      clearTimeout(progressTimer);
      clearTimeout(fadeOutTimer);
    };
  }, [
    shouldUseProgress,
    progress,
    speed,
    hasOnProgress,
    handleComplete,
    handleClose,
    onProgress,
    checkmarkDuration,
  ]);

  return {
    isShowing,
    progress,
  };
};
