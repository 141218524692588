import PropTypes from "prop-types";
import classNames from "classnames";
import { CircularProgress } from "@circle-react/components/shared/uikit/TipTap/AttachmentPreviews/CircularProgress";
import { Icon } from "@circle-react-shared/Icon";

export const ProgressIcon = ({ progress, variant, isIndefinite }) => {
  const isDangerVariant = variant === "danger";

  if (progress === 100) {
    return (
      <span className="flex h-5 w-5">
        <Icon
          type="20-checkmark-circle-fill"
          size={20}
          className={classNames("text-circle-button", {
            "!text-white": isDangerVariant,
          })}
        />
      </span>
    );
  }

  return (
    <span
      className={classNames("flex h-5 w-5 items-center justify-center", {
        "animate-spin-fast": isIndefinite,
      })}
    >
      <CircularProgress
        percentage={isIndefinite ? 50 : progress}
        size={18}
        strokeWidth={1.75}
        backgroundClassName={classNames({
          "!text-red-800": isDangerVariant,
        })}
        foregroundCircleClassName={classNames("!text-circle-button", {
          "!text-white": isDangerVariant,
        })}
      />
    </span>
  );
};

ProgressIcon.propTypes = {
  progress: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["success", "danger"]).isRequired,
  isIndefinite: PropTypes.bool,
};
