import { createContext, useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { noop, uniqueId } from "lodash";
import { Portal } from "@circle-react-uikit/Portal";
import { ToastContainer } from "./ToastContainer";
import { placements, variants } from "./constants";

const ToastContext = createContext({
  toasts: [],
  show: noop,
  remove: noop,
  success: noop,
  error: noop,
  placement: placements.bottom,
});
ToastContext.displayName = "ToastContext";

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [placement, setPlacement] = useState(placements.bottom);

  const show = useCallback((message, options) => {
    const { placement: placementProp } = options || {};
    const placement = placements[placementProp] || placements.bottom;

    const newItem = {
      id: uniqueId(),
      message,
      ...options,
    };

    setPlacement(placement);
    setToasts(prevState => [...prevState, newItem]);
  }, []);

  const success = (message, options) =>
    show(message, { ...options, variant: variants.success });

  const error = (message, options) =>
    show(message, { ...options, variant: variants.danger });

  const remove = id => {
    setToasts(prevState => prevState.filter(e => e.id != id));
  };

  window.onViewOnlyModeError = error;

  return (
    <ToastContext.Provider
      value={{
        toasts,
        show,
        success,
        error,
        remove,
        placement,
      }}
    >
      <Portal>
        <ToastContainer />
      </Portal>
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};
